import React from 'react';



function About () {
return(
    <div>
       
       <div class="container">
        <div class="content">
            <div class="row">
                <div class="col-lg-7">
                    <h2>About Bob's Skateshop</h2>
                    <p>Bobs Skateshop was founded Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus
                        necessitatibus magnam quasi ducimus minima sit fuga expedita corrupti perspiciatis architecto
                        nam reprehenderit modi, hic natus ex nisi, aliquid laudantium harum!
                        Provident deleniti, eaque maxime quia, at officiis rerum voluptatibus hic placeat nemo sapiente
                        cumque quas reiciendis labore, corrupti eligendi suscipit consectetur quos expedita pariatur
                        laudantium minus? Sunt expedita cum numquam!
                        Sed odit pariatur tempora eos labore harum velit. Repellendus, ut. Numquam accusamus illo
                        nesciunt laudantium magnam necessitatibus commodi. Maiores explicabo dolor quaerat omnis
                        officiis numquam enim dignissimos ipsa illo. Placeat.
                        Ex eum asperiores, magnam vel reiciendis quod consectetur. Optio quasi fugiat dolor corrupti
                        tenetur fuga soluta ipsa nesciunt placeat, consequuntur magnam illum eius cum exercitationem
                        asperiores molestiae, eaque ipsam ad?
                        Incidunt voluptatum, voluptatibus consectetur eum voluptate tempora, suscipit dicta esse
                        deserunt vitae qui doloremque. Accusantium iusto sapiente ut quidem accusamus, tenetur, tempora
                        dolore aspernatur nostrum voluptate delectus. Repudiandae, consequuntur aspernatur.
                        Odit voluptatum dolores debitis ipsum iusto ad vitae provident id illum error vel optio iste
                        sequi minima asperiores maxime, deserunt accusamus, recusandae quisquam, unde dicta sed
                        reiciendis dolore. Atque, aut.

                    </p>
                </div>
                <div class="col-lg-5">
                    <img src="imgs/alexander-londono-MAe6R6siGYQ-unsplash.jpg" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>

</div>
);
}

export default About;